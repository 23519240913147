import React, { Suspense } from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import {ErrorBoundary} from 'react-error-boundary';

const LazyComponent = React.lazy(() => import('./ApplicationOutputs'));

const Loadable = props => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingIndicator />}>
        <LazyComponent {...props}/>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Loadable;
