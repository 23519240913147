/* eslint-disable import/default */
import React from 'react';
import { createRoot } from 'react-dom/client';

import configureStore from './store/configureStore';
// import history from './utils/history';
require('./favicon.ico'); // Tell webpack to load favicon.ico
import 'typeface-roboto'; //Roboto fonts for material design
import Root from 'components/Root';
import 'api/axiosDefaults';
import 'autotrack';
// import {createReduxHistoryContext} from "redux-first-history";

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      // eslint-disable-next-line no-console
      registration.onupdatefound = function() {
        // eslint-disable-next-line no-console
        console.log('An update has been found...');

        const installingWorker = registration.installing;

        installingWorker.onstatechange = function() {
          switch (installingWorker.state) {
            case 'installed':
              // eslint-disable-next-line no-console
              console.log('New Service Worker installed.');
              break;

            case 'redundant':
              // eslint-disable-next-line no-console
              console.error('The installing service worker became redundant.');
              break;

            default:
              // eslint-disable-next-line no-console
              console.log('Installing Service Worker state:', installingWorker.state);
          }
        };
      };

      // eslint-disable-next-line no-console
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      // eslint-disable-next-line no-console
      console.log('SW registration failed: ', registrationError);
    });
  });
}

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  //const ReactRedux = require('react-redux');
  whyDidYouRender(React, {
    trackAllPureComponents: false,
    trackExtraHooks: [
      // [ReactRedux, 'useSelector']
    ]
  });
}

// const {
//   createReduxHistory,
// } = createReduxHistoryContext({ history });


const store = configureStore();
// const appHistory = createReduxHistory(store);

const MOUNT_NODE = document.getElementById('app');
const root = createRoot(MOUNT_NODE);
root.render(
  <Root store={store} />
);

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
