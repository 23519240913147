import { combineReducers } from 'redux';
import history from '../utils/history';
import application from './applicationReducer';
import externalSources from "./externalSourcesReducer";
import unitConversion from './unitConversionReducer';
import loadItems from './loadItemsReducer';
import calculations from './calculationsReducer';
import filters from './filtersReducer';
import userProfile from './userProfileReducer';
import connections from './connectionsReducer';
import {createReduxHistoryContext} from "redux-first-history";

const {
  routerReducer
} = createReduxHistoryContext({ history });

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: routerReducer,
    application,
    externalSources,
    unitConversion,
    loadItems,
    calculations,
    filters,
    userProfile,
    connections,
    ...injectedReducers,
  });

  return rootReducer;
}
