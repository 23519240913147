import {createStore, compose, applyMiddleware} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import {thunk} from 'redux-thunk';
import createReducer from '../reducers';
import { createReduxHistoryContext } from "redux-first-history";
import history from '../utils/history';
import 'regenerator-runtime/runtime';
import createSagaMiddleware from 'redux-saga';

const {
  routerMiddleware,
} = createReduxHistoryContext({ history });

// const connectRouterHistory = connectRouter(history);
let reduxSagaMonitorOptions = {};

function configureStore(initialState
                        // , history
  ) {
  let composeEnhancers = compose;

  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

      ////Bellow is code for REDUX-SAGA extension
      // reduxSagaMonitorOptions.sagaMonitor = window["__SAGA_MONITOR_EXTENSION__"];
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const middlewares = [
    reduxImmutableStateInvariant(),
    thunk,
    routerMiddleware,
    sagaMiddleware
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    createReducer(), // root reducer with router state
    initialState,
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  return store;
}

export default configureStore;
