export const Design = 'Design';
export const Performance = 'Performance';
export const Rating = 'Rating';

export function parseCalcMethod(value){
    switch(value){
        case 1:
            return Design;
        case 2:
            return Performance;
        case 3:
            return Rating;
        default:
            return Design;
    }
}

export function calcMethodToNo(value){
    if(typeof value === 'string'){
        switch(value.toLowerCase()){
            case "design":
                return 1;
            case "performance":
                return 2;
            case "rating":
                return 3;
            default:
                return 1;
        }
    }
    return 1;
}