import React from 'react';
import HomePage from 'containers/Home/Loadable';
import NotFoundPage from 'components/NotFoundPage';
import CalculationPage from '../../containers/calculations/index';
import SavedCalculationList from 'containers/savedCalculationList/Loadable';
import MulticalcSavedCalculationsPage from 'containers/multicalc/savedCalculations/Loadable';
import FluidProperties from 'containers/fluidProperties/Loadable';
import FluidEditor from "containers/fluidEditor/Loadable";
import PressureDropTool from "containers/pressureDropTool/Loadable";
import CipTool from 'containers/cipTool/Loadable';
import ProductSelector from 'containers/productSelector/Loadable';
import ProductBasket from 'components/ProductBasket/Loadable';
import SettingsPage from 'containers/settings/Loadable';
import MulticalcPage from 'containers/multicalc/Loadable';
import LoginPage from 'containers/Login/Login';
import CxPortalLoginPage from 'containers/Login/CxPortal/LoginPage';
import CxPortalCallbackPage from 'containers/Login/CxPortal/CallbackPage';
import CxPortalLogoutPage from 'containers/Login/CxPortal/LogoutPage';
/*import ForgottenPassword from 'containers/Login/ForgottenPassword';
import Register from 'containers/Login/Register';*/
// import VerifyUser from 'containers/Login/VerifyUser';
import XRefPage from '../../containers/xReference/Loadable';
import EasySelect from 'containers/EasySelect/Loadable';
import EasySelectTapWater from 'containers/EasySelect/TapWater/Loadable';
import EasySelectHeating from 'containers/EasySelect/Heating/Loadable';
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter
} from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import {AllCalculationTypes} from "../../constants/calculationTypes";
import {RouterPaths} from "./RouterPaths";

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" lazy={async () => {
    const c = await import('./App');
    return { Component: c.default };
  }}>
    <Route path={RouterPaths.FORGOTTENPASSWORD} lazy={async () => {
      const c = await import('../../containers/Login/ForgottenPassword');
      return { Component: c.default };
    }}/>
    <Route path={RouterPaths.REGISTER} lazy={async () => {
      const c = await import('../../containers/Login/Register');
      return { Component: c.default };
    }} />
    <Route path={`${RouterPaths.VERIFY}/:verificationString`} lazy={async () => {
      const c = await import('../../containers/Login/VerifyUser');
      return { Component: c.default };
    }} />
    <Route path="/" element={<PrivateRoute />}>
      <Route path="/" element={<HomePage />} />
    </Route>
    <Route path={RouterPaths.XREFERENCE} element={<XRefPage />}>
      <Route exact path=":paramManufacturer?" element={<XRefPage />}>
        <Route exact path=":paramModel" element={<XRefPage />}>
          <Route exact path=":paramNop" element={<XRefPage />} />
        </Route>
      </Route>
    </Route>
    <Route path={RouterPaths.LOGIN} element={<LoginPage />}/>
    {AllCalculationTypes.map(r => {
      return(
        <Route key={r} path={`/${r}`} element={<PrivateRoute />}>
          <Route path={`/${r}`} element={<CalculationPage />}>
            <Route path=":calculation" element={<CalculationPage />}>
              <Route path=":calcId" element={<CalculationPage />} />
            </Route>
          </Route>
        </Route>
      );
    })}
    <Route path={RouterPaths.SAVEDCALCULATIONS} element={<PrivateRoute />}>
      <Route path={RouterPaths.SAVEDCALCULATIONS} element={<SavedCalculationList />}/>
    </Route>
    <Route path={RouterPaths.LOGIN_CX_PORTAL_CALLBACK} element={<CxPortalCallbackPage />}/>
    <Route path={RouterPaths.LOGIN_CX_PORTAL} element={<CxPortalLoginPage />}/>
    <Route path={RouterPaths.LOGOUT_CX_PORTAL} element={<CxPortalLogoutPage />}/>
    <Route path={RouterPaths.SETTINGS} element={<PrivateRoute />}>
      <Route path={RouterPaths.SETTINGS} element={<SettingsPage />} />
    </Route>
    <Route path={RouterPaths.PRODUCTSELECTOR} element={<PrivateRoute />}>
      <Route path={RouterPaths.PRODUCTSELECTOR} element={<ProductSelector />}/>
    </Route>
      <Route path={RouterPaths.PRODUCTBASKET} element={<PrivateRoute />}>
        <Route path={RouterPaths.PRODUCTBASKET} element={<ProductBasket />}/>
      </Route>
    <Route path={RouterPaths.FLUIDPROPERTIES} element={<PrivateRoute />}>
      <Route path={RouterPaths.FLUIDPROPERTIES} element={<FluidProperties />}>
        <Route path=":fluidId" element={<FluidProperties />}>
          <Route path=":amount" element={<FluidProperties />}>
            <Route path=":unit" element={<FluidProperties />}>
              <Route path=":isGas" element={<FluidProperties />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path={`${RouterPaths.FLUIDPROPERTIES}/:fluidId/:amount//:isGas`} element={<FluidProperties />} />
    </Route>
    <Route path={RouterPaths.FLUID_EDITOR} element={<PrivateRoute />}>
      <Route path={RouterPaths.FLUID_EDITOR} element={<FluidEditor />}/>
    </Route>
    <Route path={RouterPaths.PRESSURE_DROP_TOOL} element={<PrivateRoute />}>
      <Route path={RouterPaths.PRESSURE_DROP_TOOL} element={<PressureDropTool />}/>
    </Route>
    <Route path={RouterPaths.CIP_TOOL} element={<PrivateRoute />}>
      <Route path={RouterPaths.CIP_TOOL} element={<CipTool />}/>
    </Route>
    <Route path={RouterPaths.MULTICALC} element={<PrivateRoute />}>
      <Route path={RouterPaths.MULTICALC} element={<MulticalcPage />}>
        <Route path=":calcId" element={<MulticalcPage />} />
      </Route>
    </Route>
    <Route path={RouterPaths.MULTICALCSAVEDCALCULATIONS} element={<PrivateRoute />}>
      <Route path={RouterPaths.MULTICALCSAVEDCALCULATIONS} element={<MulticalcSavedCalculationsPage />}/>
    </Route>
    <Route path={RouterPaths.EASYSELECT} element={<EasySelect />}/>
    <Route path={RouterPaths.EASYSELECT_TAPWATER} element={<EasySelectTapWater />}/>
    <Route path={RouterPaths.EASYSELECT_HEATING} element={<EasySelectHeating />}/>
    <Route path="*" element={<NotFoundPage />}/>
  </Route>
));


export default router;


