import { produce } from 'immer';
import initialState from './initialState';
import * as initialStates from '../constants/initialStates';
import * as types from 'constants/actionTypes';

const calculationsReducer = (state = initialState.calculations, action) =>
    produce(state, draft => {
        let newState;
        switch (action.type) {
            case types.Calculation.INPUTS_LOAD:
                draft[action.calculationType] = {
                    ...draft[action.calculationType],
                    loading: true,
                    error: false,
                    response: {},
                    selectedExchanger: null,
                    calculation: {}

                };
                return draft;
            case types.Calculation.INPUTS_LOAD_SUCCESS: {
                const inputParams = {...action.value };
                draft = {
                    [action.calculationType]: {
                        ...draft[action.calculationType],
                        loading: false,
                        error: false,
                        inputParams: inputParams,
                        calculation: {
                            type: action.calculationType,
                            method: action.value.userCalculationInputs.calculationMethod,
                            id: action.calculationId
                        },
                        selectedExchanger: null
                    }
                };

                return draft;
            }
            case types.Calculation.UPDATE_PARAMETERS: {
                const values = action.values || [];
                values.forEach(o => {
                    draft[action.calculationType].inputParams.userCalculationInputs[o.fieldName] = o.value;
                });
                return draft;
            }
            case types.Calculation.SET_INPUTS_VALIDITY:
                draft[action.calculationType].inputParams.isValid= action.value;
                return draft;
            case types.Calculation.INPUTS_LOAD_ERROR:
                draft.loading = true;
                draft.error = false;
                draft[action.calculationType] = {
                    loading: false,
                    error: action.error,
                    inputs: {}
                };
                return draft;

            // case CALCULATION_INPUTS_HEATEXCHANGERS_LOAD:
            //     break;
            case types.Calculation.INPUTS_HEATEXCHANGERS_SUCCESS:
                draft[action.calculationType].inputParams.availableHeatExchangers = action.exchangers;
                break;
            case types.Calculation.CALCULATE_BEGIN: {
                draft =  {
                    [action.calculationType]: {
                        ...draft[action.calculationType],
                        isCalculating: true,
                        selectedExchanger: null
                    }
                };
                return draft;
            }
            case types.Calculation.CALCULATE_SUCCESS: {
                draft = {
                    [action.calculationType]: {
                        ...draft[action.calculationType],
                        selectedExchanger: state[action.calculationType].selectedExchanger,
                        response: action.value,
                        isCalculating: false
                    }
                };

                return draft;
            }
            case types.SELECT_HEAT_EXCHANGER: {
                draft[action.calculationType].selectedExchanger = action.selectedExchanger;
                return draft;
            }
            case types.SELECT_SUB_RESULT:
              draft[action.calculationType].selectedExchanger = action.selectedResult;
              draft[action.calculationType].selectedSubResult = action.selectedSubResult;
              break;
            case types.FILTERS_SELECTION_CHANGED1: {
                draft[action.calculationType].inputParams.filterSettings[action.name] = action.selection;
                break;
            }
            case types.CONNECTIONS_CLEAR: {
                newState = {
                    ...state, SinglePhase: {
                        ...state.SinglePhase, selectedExchanger: {
                            ...state.SinglePhase.selectedExchanger,
                            connections: initialStates.SinglePhase.selectedExchanger.connections
                        }
                    }
                };
                return newState;
            }
            case types.Calculation.FILTERS_CHANGE:
                draft[action.calculationType].inputParams.filterSettings = action.filterSelection;
            break;
          case types.Calculation.OUTPUT_COLUMNS_CHANGE:
            draft[action.calculationType].response.calculationOutputColumns = action.userDefaultCalculationOutputColumns;

            break;
            default:
                return state;
        }
    });

export default calculationsReducer;
