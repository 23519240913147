import React, { StrictMode } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
// import "core-js/es/array/includes";
// import "core-js/stable";
import {RouterProvider} from 'react-router-dom';
import r from '../containers/App/rrr';

const Root = ({ store }) => {
  return (
    <StrictMode>
      <Provider store={store}>
        <RouterProvider router={r}/>
      </Provider>
    </StrictMode>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};


export default Root;
