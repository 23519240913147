import * as type from 'constants/actionTypes';
import axios from 'axios';
import decodeJwt from "../utils/decodeJWT";

export function getUserIdFromLocalStorageToken() {
    const TOKEN_KEY = 'idtoken';
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) return null;
    const decodedJwt = decodeJwt(token);
    if (!decodedJwt || !decodedJwt.data) return null;
    return decodedJwt.data.userId;
}

export function errorResponse(response){
    const {data} = response;
    if(data && data.errors){
        if(Array.isArray(data.errors.msg)){
            return data.errors.msg.map(e => getErrorMessage(e.msg));
        } else {
            return [getErrorMessage(data.errors.msg)];
        }
    }
    return null;
}

const getErrorMessage = errorCode => {
    switch(errorCode){
        case 'WRONG_PASSWORD':
            return 'Username or password is invalid';
        case 'USER_NOT_VERIFIED':
            return 'User is not verified.';
        case 'BLOCKED_USER':
            return 'Your account has been temporarily blocked. \n\r Please try again in 5 minutes.';
        case 'PASSWORD_TOO_SHORT_MIN_5':
            return 'Password is too short (min 5 characters)';
        case 'EMAIL_IS_NOT_VALID':
            return 'Email is not valid!';
        case 'EMAIL_ALREADY_EXISTS':
            return 'Email is already registered!';
        case 'NOT_FOUND_OR_ALREADY_VERIFIED':
            return 'Email was not found or is already registered';
      case 'USER_NOT_REGISTERED':
            return 'User is not registered for DThermX. Please make a registration.';
        default:
            return `Error code: ${errorCode}`;
    }
};

export function processAuthentication(token){
    const decodedJwt = decodeJwt(token);
    const expiresAt = decodedJwt.exp;
    const userId = decodedJwt.data.userId;
    localStorage.setItem('idtoken', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return {
      userId,
      expiresAt,
      state: 'loaded'
    };
}


export function getAuthenticatedUserId() {
  const token = localStorage.getItem('idtoken');
  if(token){
    const authState = processAuthentication(token);
    if(isAuthenticated(authState)) {
      return authState.userId;
    }
  }

  return null;
}


export function isAuthenticated(authState){
  if(!authState.userId && authState.expiresAt){
    return false;
  }
  if(authState.state === 'out'){
    return false;
  }
  const isTokenValid = new Date().getTime() / 1000 < authState.expiresAt;
  return isTokenValid;
}

export function handleAuthentication(dispatch){
  const token = localStorage.getItem('idtoken');
  if(token){
    const authState = processAuthentication(token);
    if(isAuthenticated(authState)){
      dispatch({type: type.Auth.SAGA_HANDLE_AUTH, token, expiresAt: authState.expiresAt, userId: authState.userId, state: authState.state});
    } else {
      dispatch({type: type.Auth.SAGA_HANDLE_AUTH, token, expiresAt: authState.expiresAt, userId: authState.userId, state: 'out'});
    }
  } else {
    dispatch({type: type.Auth.SAGA_HANDLE_AUTH, token, expiresAt: null, userId: null, state: 'out'});
  }

  axios.interceptors.response.use(
    response => response,
    error => {
      const {status} = error.response;
      if (status === 401) {
        dispatch({
          type: type.Application.ERROR_UNAUTHORIZED,
          value: {
            title: 'Not authorized',//title,
            message: 'User is not authorized, please login to continue',//error.response.data,
            error: error
        }});
        logout(dispatch);
      }
      return Promise.reject(error);
  });
}

export function logout(dispatch) {
    localStorage.removeItem('idtoken');
    dispatch({type: type.Auth.REDUX_LOG_OUT});
}
