import {
  SinglePhase,
  SinglePhaseDual,
  Condenser,
  CondenserDual,
  CondenserHeatPump,
  Evaporator,
  EvaporatorDual,
  EvaporatorHeatPump,
  LiquidEvaporator,
  Cascade,
  TwoStage,
  AirDryer,
  DistrictEnergy,
  AHRI
} from 'constants/calculationTypes';

export class RouterPaths {
  static SINGLEPHASE= `/${SinglePhase}/:calculation/:calcId`;
  static SINGLEPHASEDUAL= `/${SinglePhaseDual}/:calculation/:calcId`;
  static EVAPORATOR= `/${Evaporator}/:calculation/:calcId`;
  static EVAPORATORDUAL= `/${EvaporatorDual}/:calculation/:calcId`;
  static EVAPORATOR_HEAT_PUMP= `/${EvaporatorHeatPump}/:calculation/:calcId`;
  static LIQUIDEVAPORATOR= `/${LiquidEvaporator}/:calculation/:calcId`;
  static CONDENSER= `/${Condenser}/:calculation/:calcId`;
  static CONDENSERDUAL= `/${CondenserDual}/:calculation/:calcId`;
  static CONDENSER_HEAT_PUMP= `/${CondenserHeatPump}/:calculation/:calcId`;
  static CASCADE= `/${Cascade}/:calculation/:calcId`;
  static TWOSTAGE= `/${TwoStage}/:calculation/:calcId`;
  static AIRDRYER= `/${AirDryer}/:calculation/:calcId`;
  static DISTRICTENERGY= `/${DistrictEnergy}/:calculation/:calcId`;
  static AHRI= `/${AHRI}/:calculation/:calcId`;
  static SAVEDCALCULATIONS = '/savedCalculations';
  static PRODUCTBASKET= "/productbasket";
  static PRODUCTSELECTOR= "/productSelector";
  static CONNECTIONIMPACT= "/connectionImpact";
  static SETTINGS = "/settings";
  static FLUIDPROPERTIES= "/fluidProperties";
  static FLUID_EDITOR = "/fluidEditor";
  static PRESSURE_DROP_TOOL = "/pressureDrop";
  static CIP_TOOL = "/cipTool";
  static ABOUT= "/about";
  static LOGIN= "/login";
  static LOGIN_CX_PORTAL = "/login/cx-portal";
  static LOGIN_CX_PORTAL_CALLBACK = "/login/cx-portal/callback";
  static LOGOUT_CX_PORTAL = '/logout/cx-portal';
  static FORGOTTENPASSWORD= "/forgottenpassword";
  static REGISTER= "/register";
  static VERIFY= "/verify";
  static XREFERENCE= "/xreference";
  static MULTICALC= "/multicalc";
  static MULTICALCSAVEDCALCULATIONS= "/multicalc-savedcalculations";
  static EASYSELECT= "/easyselect";
  static EASYSELECT_TAPWATER= "/easyselect-tapwater";
  static EASYSELECT_HEATING= "/easyselect-heating";
}
