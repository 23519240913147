import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import browserHistory from 'utils/history';

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.applicationInsightConnectionString,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    },
    /* ...Other Configuration Options... */
    //autoTrackPageVisitTime: true,
    //enableAutoRouteTracking: true,
    disableTelemetry: false
  }
});

appInsights.loadAppInsights();

export default appInsights;
export {
  reactPlugin,
  appInsights
};
