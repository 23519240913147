import {
    SAGA_ITEM_CREATE_TENDER_PRINTOUT,
    SAGA_ITEM_CREATE_TENDER_PRINTOUT_SUCCESS,
    SAGA_ITEM_CREATE_TENDER_PRINTOUT_ERROR,
    SAGA_ITEM_CREATE_BASKET_PRINTOUT,
    SAGA_ITEM_CREATE_BASKET_PRINTOUT_SUCCESS,
    SAGA_ITEM_CREATE_BASKET_PRINTOUT_ERROR,
    SAGA_ITEM_CREATE_RESULT_PRINTOUT,
    SAGA_ITEM_CREATE_RESULT_PRINTOUT_SUCCESS,
    SAGA_ITEM_CREATE_RESULT_PRINTOUT_ERROR,
    SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT,
    SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT_SUCCESS,
    SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT_ERROR,
    SAGA_ITEM_REVOKE_OBJECT
} from 'constants/actionTypes';

export function createBasketTenderPrintout(userId, basketItemId) {
  return {
    type: SAGA_ITEM_CREATE_TENDER_PRINTOUT,
    userId: userId,
    printoutId: basketItemId
  };
}

export function createBasketTenderPrintoutSuccess(basketItemId, url, filename, contentType) {
  return {
    type: SAGA_ITEM_CREATE_TENDER_PRINTOUT_SUCCESS,
    printoutId: basketItemId,
    url: url,
    filename: filename,
    contentType: contentType
  };
}

export function createBasketTenderPrintoutError(basketItemId, error) {
  return {
    type: SAGA_ITEM_CREATE_TENDER_PRINTOUT_ERROR,
    error: error,
    printoutId: basketItemId
  };
}


export function createBasketPrintout(userId, basketItemId, selectedPrintoutFormat, quoteInfo) {
    return {
        type: SAGA_ITEM_CREATE_BASKET_PRINTOUT,
        userId: userId,
        printoutId: basketItemId,
        selectedPrintoutFormat: selectedPrintoutFormat,
        quoteInfo: quoteInfo,
    };
}

export function createBasketPrintoutSuccess(basketItemId, url, filename, contentType) {
    return {
        type: SAGA_ITEM_CREATE_BASKET_PRINTOUT_SUCCESS,
        printoutId: basketItemId,
        url: url,
        filename: filename,
        contentType: contentType
    };
}

export function createBasketPrintoutError(basketItemId, error) {
    return {
        type: SAGA_ITEM_CREATE_BASKET_PRINTOUT_ERROR,
        error: error,
        printoutId: basketItemId
    };
}

export function createCalculationResultPrintout(calculationType, selectedArticle, selectedAccessories, selectedXpc, selectedPrintoutFormat, isQuote, quoteInfo, portInfo) {
    return {
        type: SAGA_ITEM_CREATE_RESULT_PRINTOUT,
        calculationType: calculationType,
        selectedArticle: selectedArticle,
        selectedAccessories: selectedAccessories,
        selectedXpc: selectedXpc,
        selectedPrintoutFormat: selectedPrintoutFormat,
        printoutId: Math.random(),
        isQuote: isQuote,
        quoteInfo: quoteInfo,
        portInfo: portInfo
    };
}

export function createCalculationResultPrintoutSuccess(printoutId, url, filename, contentType) {
    return {
        type: SAGA_ITEM_CREATE_RESULT_PRINTOUT_SUCCESS,
        printoutId: printoutId,
        url: url,
        filename:filename,
        contentType: contentType
    };
}

export function createCalculationResultPrintoutError(printoutId, error) {
    return {
        type: SAGA_ITEM_CREATE_RESULT_PRINTOUT_ERROR,
        error: error,
        printoutId: printoutId
    };
}

export function createApplicationResultPrintout(calculationType, selectedPrintoutFormat, isQuote, quoteInfo, portInfo, selectedApplicationResultComponent) {
 return {
   type: SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT,
   calculationType: calculationType,
   printoutId: Math.random(),
   selectedPrintoutFormat: selectedPrintoutFormat,
   isQuote: isQuote,
   quoteInfo: quoteInfo,
   portInfo: portInfo,
   selectedApplicationResultComponent: selectedApplicationResultComponent
 };
}

export function createApplicationResultPrintoutSuccess(printoutId, url, filename, contentType) {
  return {
    type: SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT_SUCCESS,
    printoutId: printoutId,
    url: url,
    filename: filename,
    contentType: contentType
  };
}

export function createApplicationResultPrintoutError(printoutId, error) {
  return {
    type: SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT_ERROR,
    error: error,
    printoutId: printoutId
  };
}

export function revokeObject(url) {
  return {
    type: SAGA_ITEM_REVOKE_OBJECT,
    url: url
  };
}
