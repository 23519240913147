import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectFilters = state => state.filters;
const selectCalculations = state => state.calculations || {};

const makeSelectCalculations = () =>
    createSelector(
        selectCalculations,
        calculationsState => calculationsState
    );

const makeSelectCalculation = (calculationType) => {
    return createSelector(
        selectCalculations,
        calculationsState => calculationsState[calculationType]
    );
};

const makeSelectCalculationInputParams = (calculationType) => {
  return createSelector(
    selectCalculations,
    calculationsState => get(calculationsState, `[${calculationType}].inputParams`, null)
  );
};

const makeSelectCalculationInputParamsUserCalculationInputs = (calculationType) => {
    return createSelector(
        selectCalculations,
        calculationsState => get(calculationsState, `[${calculationType}].inputParams.userCalculationInputs`, null)
    );
};

const makeSelectCalculationInputParamsFilterSettings = (calculationType) => {
    return createSelector(
        selectCalculations,
        calculationsState => get(calculationsState, `[${calculationType}].inputParams.filterSettings`, null)
    );
};

const makeSelectCalculationResponse = (calculationType) =>
    createSelector(
        selectCalculations,
        calculationsState => get(calculationsState, `[${calculationType}].response`, null)
    );

const makeSelectInputParamsValidity = (calculationType) => {
    return createSelector(
        selectCalculations,
        calculationsState => calculationsState[calculationType] ? (calculationsState[calculationType].inputParams ? calculationsState[calculationType].inputParams.isValid: false ) : false
    );
};

const makeSelectCalculationFilters = (calculationType) => {
    return createSelector(
        selectCalculations,
        calculationsState => {
            return get(calculationsState, `[${calculationType}].inputParams.filterSettings`, null);
        }
    );
};

const makeSelectCalculationProps = (calculationType) => {
    return createSelector(
        selectCalculations,
        calculationsState => {
          return calculationsState[calculationType] ? calculationsState[calculationType].calculation : null;
        }
    );
};

const makeSelectIsCalculating = (calculationType) => {
    return createSelector(
        selectCalculations,
        calculationsState => calculationsState[calculationType] ? calculationsState[calculationType].isCalculating || false : false
    );
};

const makeSelectIsCalcLoaded = (calculationType) => {
    return createSelector(
        selectCalculations,
        calculationsState => {
            const isLoading =  calculationsState[calculationType] ? calculationsState[calculationType].loading || false : false;
            return !isLoading && calculationsState[calculationType] ? true: false;
        }
    );
};

const makeSelectSelectedProduct = (calculationType) =>
    createSelector(
        selectCalculations,
        calculation => {
            const selectedResult = get(calculation, `[${calculationType}].selectedExchanger`, null);
            const selectedSubResult = get(calculation, `[${calculationType}].selectedSubResult`, null);
            return selectedSubResult || selectedResult;
        }
    );

const makeSelectSelectedResult = (calculationType) =>
  createSelector(
    selectCalculations,
    calculation => get(calculation, `[${calculationType}].selectedExchanger`, null)
  );

const makeSelectSelectedSubResult = (calculationType) =>
  createSelector(
    selectCalculations,
    calculation => get(calculation, `[${calculationType}].selectedSubResult`, null)
  );

const makeSelectSelectedFilters = (calculationType) =>
    createSelector(
        selectCalculations,
        calculation => { return calculation[calculationType] ? calculation[calculationType].filters : null;}
    );

const makeSelectFilters = () =>
    createSelector(
        selectFilters,
        filtersState => filtersState
    );

export {
    selectCalculations,
    makeSelectCalculations,
    makeSelectFilters,
    makeSelectCalculation,
    makeSelectCalculationInputParams,
    makeSelectCalculationInputParamsUserCalculationInputs,
    makeSelectCalculationInputParamsFilterSettings,
    makeSelectCalculationResponse,
    makeSelectCalculationFilters,
    makeSelectSelectedProduct,
    makeSelectSelectedResult,
    makeSelectSelectedSubResult,
    makeSelectSelectedFilters,
    makeSelectCalculationProps,
    makeSelectIsCalculating,
    makeSelectIsCalcLoaded,
    makeSelectInputParamsValidity
};
