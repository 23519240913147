import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectCalculationResponse,
  makeSelectSelectedResult,
  makeSelectSelectedSubResult,
  makeSelectCalculationInputParams
} from "selectors/calculationSelectors";
import {makeSelectUserProfile} from "selectors/userProfileSelectors";
import {getProductNumber, selectHeatExchanger, selectSubResult } from 'actions/calculationActions';
import get from 'lodash/get';
import 'styles/styles.scss';
import {createApplicationResultPrintout, createCalculationResultPrintout} from "../../../actions/printoutActions";
import {CalculationResponseType} from "../../../constants/CalculationResponseType.ts";
import CalculationOutputs from './Calculation/CaculationOutputsLoadable';
import ApplicationOutputs from './Application/ApplicationOutputsLoadable';
import { useNavigate } from 'react-router-dom';
import {RouterPaths} from '../../../containers/App/RouterPaths';

const ResultDispatcher = ({ calculationType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const response = useSelector(makeSelectCalculationResponse(calculationType));
  const inputs = useSelector(makeSelectCalculationInputParams(calculationType));
  const selectedResult = useSelector(makeSelectSelectedResult(calculationType));
  const selectedSubResult = useSelector(makeSelectSelectedSubResult(calculationType));
  const userProfile = useSelector(makeSelectUserProfile());

  const handleGetProductNumber = useCallback((xpc, callback) => {
    dispatch(getProductNumber(xpc, callback));
  }, [dispatch]);

  const handleOnExchangerChange = useCallback((calculationType, result) => {
    dispatch(selectHeatExchanger(calculationType, result));
  }, [dispatch]);

  const handleOnSelectedSubResultChange = useCallback((calculationType, result, subResult) => {
    dispatch(selectSubResult(calculationType, result, subResult));
  }, [dispatch]);

  const handleCreateCalculationResultPrintout = useCallback((userId, selectedPrintoutLanguage, selectedPrintoutFormat, basketItem, isQuote, quoteInfo, portInfo) => {
    dispatch(createCalculationResultPrintout(userId, selectedPrintoutLanguage, selectedPrintoutFormat, basketItem, isQuote, quoteInfo, portInfo));
  }, [dispatch]);

  const handleCreateApplicationResultPrintout = useCallback((userId, selectedPrintoutLanguage, selectedPrintoutFormat, calculationType, calculationMethod, calculationRequest, selectedApplicationResult, isQuote, quoteInfo, portInfo, selectedApplicationResultComponent) => {
    dispatch(createApplicationResultPrintout(userId, selectedPrintoutLanguage, selectedPrintoutFormat, calculationType, calculationMethod, calculationRequest, selectedApplicationResult, isQuote, quoteInfo, portInfo, selectedApplicationResultComponent));
  }, [dispatch]);

  const handleProductSelectorOpen = useCallback(() => {
    navigate(RouterPaths.PRODUCTSELECTOR, { state: { calculationType: calculationType } });
  }, [calculationType, navigate]);

  const calculationResponseType = get(response, 'calculationResponseType', null);
  switch (calculationResponseType) {
    case CalculationResponseType.Calculation: {
      return (<CalculationOutputs
        calculationType={calculationType}
        onExchangerChange={handleOnExchangerChange}
        response={response}
        inputs={inputs}
        selectedResult={selectedResult}
        createCalculationResultPrintout={handleCreateCalculationResultPrintout}
        onProductSelectorOpen={handleProductSelectorOpen}
        userProfile={userProfile}
        getProductNumber={handleGetProductNumber}/>);
    }
    case CalculationResponseType.Application: {
      return (<ApplicationOutputs
        calculationType={calculationType}
        onSelectedSubResultChange={handleOnSelectedSubResultChange}
        response={response}
        inputs={inputs}
        selectedResult={selectedResult}
        selectedSubResult={selectedSubResult}
        userProfile={userProfile}
        handleCreateApplicationResultPrintout={handleCreateApplicationResultPrintout}
        getProductNumber={handleGetProductNumber}/>);
    }
    default:
      return null;
  }
};

ResultDispatcher.propTypes = {
  calculationType: PropTypes.string,
};

export default React.memo(ResultDispatcher);
