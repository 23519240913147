import { createSelector } from 'reselect';

const selectFilters = state => state.filters || {};

const makeSelectFilters = () => 
    createSelector(
      selectFilters,
      selectFilters => selectFilters
    );

const makeSelectDefaultSelection = () =>
    createSelector(
      selectFilters,
      filtersState => filtersState.defaultSelection
    );

export { 
    makeSelectFilters,
    makeSelectDefaultSelection
};