import { produce } from 'immer';
import initialState from './initialState';
import * as types from '../constants/actionTypes';

const userProfileReducer = (state = initialState.userProfile, action) =>
    produce(state, draft => {
        switch (action.type) {
            case types.LOAD_USER_PROFILE_DATA_SUCCESS:
              return {...action.userProfileData};
            case types.SAVE_USER_PROFILE_DATA_SUCCESS:
              return {...action.userProfileData};
            case types.SAVE_USER_PROFILE_DATA_ERROR:
              return state;
            case types.APPLICATION_LANGUAGE_CHANGE:
                draft.settings.workingLanguage = action.language;
              break;
            case types.PRINTOUT_LANGUAGE_CHANGE:
              draft.settings.printouts.languagePrimary = action.language;
              break;
            case types.UNIT_SYSTEM_LANGUAGE_CHANGE:
                draft.settings.units = action.unitSystem;
              break;
            case types.SHOW_WELCOME_DIALOG_CHANGE:
              draft.settings.showWelcomeDialog = action.showWelcomeDialog;
              break;
            case types.ALLOW_TELEMETRY:
              draft.settings.allowTelemetry = action.allowTelemetry;
              break;
            case types.Calculation.FILTERS_SAVE_SUCCESS:{
              if (action.calculationType == null) {
                  draft.settings.filterSettings.defaultCalculationFilterSettings = action.calculationFilters;
              } else {
                  let temp = (state.settings.filterSettings.calculationTypeFilterSettings || []).filter(x => x.calculationType !== action.calculationType);
                  draft.settings.filterSettings.calculationTypeFilterSettings = [...temp, { calculationType: action.calculationType, calculationFilterSettingsData: action.calculationFilters }];
              }
            }
            break;
            case types.Calculation.FILTERS_RESET_SUCCESS:
              if (action.calculationType == null) {
                  draft.settings.filterSettings.defaultCalculationFilterSettings = null;
              } else {
                  let temp = (state.settings.filterSettings.calculationTypeFilterSettings || []).filter(x => x.calculationType !== action.calculationType);
                  draft.settings.filterSettings.calculationTypeFilterSettings = [...temp];
              }
            break;
            default:
                return state;
        }
    });

export default userProfileReducer;
