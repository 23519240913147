export const LATEST_NEWS_LOAD = 'LATEST_NEWS_LOAD';
export const LATEST_NEWS_LOAD_SUCCESS = 'LATEST_NEWS_LOAD_SUCCESS';
export const LATEST_NEWS_LOAD_ERROR = 'LATEST_NEWS_LOAD_ERROR';
export const CASE_STORIES_LOAD = 'CASE_STORIES_LOAD';
export const CASE_STORIES_LOAD_SUCESS = 'CASE_STORIES_LOAD_SUCESS';

export const CAROUSEL_ITEMS_LOAD = 'CAROUSEL_ITEMS_LOAD';
export const CAROUSEL_ITEMS_LOAD_SUCCESS = 'CAROUSEL_ITEMS_LOAD_SUCCESS';
export const CAROUSEL_ITEMS_LOAD_ERROR = 'CAROUSEL_ITEMS_LOAD_ERROR';

export const CONVERT_FLOW_SUCCESS = 'CONVERT_FLOW_SUCCESS';

export const SELECT_HEAT_EXCHANGER = 'SELECT_HEAT_EXCHANGER';
export const SELECT_SUB_RESULT = 'SELECT_SUB_RESULT';

export const LOAD_FLUIDS_SP_SUCCESS = 'LOAD_FLUIDS_SP_SUCCESS';
export const LOAD_SKETCHES = 'LOAD_SKETCHES';

export const LOAD_FILTER_DATA = 'LOAD_FILTER_DATA';
export const LOAD_FILTER_DATA_SUCCESS = 'LOAD_FILTER_DATA_SUCCESS';
export const LOAD_FILTER_DATA_ERROR = 'LOAD_FILTER_DATA_ERROR';
export const FILTERS_SET = 'FILTERS_SET';
export const FILTERS_SELECTION_CHANGED1 = 'FILTERS_SELECTION_CHANGED1';

export const CONNECTIONS_CLEAR = 'CONNECTIONS_CLEAR';
export const CONNECTIONS_PORT_INFO_LOAD = 'CONNECTIONS_PORT_INFO_LOAD';
export const CONNECTIONS_PORT_FLOW_INFO_LOAD = 'CONNECTIONS_PORT_FLOW_INFO_LOAD';

export const LOAD_USER_PROFILE_DATA_SUCCESS = 'LOAD_USER_PROFILE_DATA_SUCCESS';
export const LOAD_USER_PROFILE_DATA_ERROR = 'LOAD_USER_PROFILE_DATA_ERROR';
export const SAVE_USER_PROFILE_DATA_SUCCESS = 'SAVE_USER_PROFILE_DATA_SUCCESS';
export const SAVE_USER_PROFILE_DATA_ERROR = 'SAVE_USER_PROFILE_DATA_ERROR';

export const ACCESSORIES_GROUPS = 'ACCESSORIES_GROUPS';

export const APPLICATION_LANGUAGE_CHANGE = "APPLICATION_LANGUAGE_CHANGE";
export const PRINTOUT_LANGUAGE_CHANGE = "PRINTOUT_LANGUAGE_CHANGE";
export const UNIT_SYSTEM_LANGUAGE_CHANGE = "UNIT_SYSTEM_LANGUAGE_CHANGE";
export const SHOW_WELCOME_DIALOG_CHANGE = 'SHOW_WELCOME_DIALOG_CHANGE';
export const ALLOW_TELEMETRY = 'ALLOW_TELEMETRY';

export const PRODUCT_NUMBER_GET = 'SAGA.GetProductNumber';

//Tender printouts
export const SAGA_ITEM_CREATE_TENDER_PRINTOUT = 'SAGA_ITEM_CREATE_TENDER_PRINTOUT';
export const SAGA_ITEM_CREATE_TENDER_PRINTOUT_SUCCESS = 'SAGA_ITEM_CREATE_TENDER_PRINTOUT_SUCCESS';
export const SAGA_ITEM_CREATE_TENDER_PRINTOUT_ERROR = 'SAGA_ITEM_CREATE_TENDER_PRINTOUT_ERROR';

//Printouts
export const SAGA_ITEM_CREATE_BASKET_PRINTOUT = 'SAGA_ITEM_CREATE_BASKET_PRINTOUT';
export const SAGA_ITEM_CREATE_BASKET_PRINTOUT_SUCCESS = 'SAGA_ITEM_CREATE_BASKET_PRINTOUT_SUCCESS';
export const SAGA_ITEM_CREATE_BASKET_PRINTOUT_ERROR = 'SAGA_ITEM_CREATE_BASKET_PRINTOUT_ERROR';

export const SAGA_ITEM_CREATE_RESULT_PRINTOUT = 'SAGA_ITEM_CREATE_RESULT_PRINTOUT';
export const SAGA_ITEM_CREATE_RESULT_PRINTOUT_SUCCESS = 'SAGA_ITEM_CREATE_RESULT_PRINTOUT_SUCCESS';
export const SAGA_ITEM_CREATE_RESULT_PRINTOUT_ERROR = 'SAGA_ITEM_CREATE_RESULT_PRINTOUT_ERROR';

export const SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT = 'SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT';
export const SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT_SUCCESS = 'SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT_SUCCESS';
export const SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT_ERROR = 'SAGA_ITEM_CREATE_APPLICATION_RESULT_PRINTOUT_ERROR';

export const SAGA_ITEM_REVOKE_OBJECT = 'SAGA_ITEM_REVOKE_OBJECT';

//Drawings
export const SAGA_ITEM_CREATE_BASKET_DRAWING = "SAGA_ITEM_CREATE_BASKET_DRAWING";
export const SAGA_ITEM_CREATE_BASKET_DRAWING_SUCCESS = "SAGA_ITEM_CREATE_BASKET_DRAWING_SUCCESS";
export const SAGA_ITEM_CREATE_BASKET_DRAWING_ERROR = "SAGA_ITEM_CREATE_BASKET_DRAWING_ERROR";

//Product selector
export const ACCESSORIES_LOAD = 'ACCESSORIES_LOAD';
export const ACCESSORIES_LOAD_SUCCESS = 'ACCESSORIES_LOAD_SUCCESS';
export const ACCESSORIES_LOAD_ERROR = 'ACCESSORIES_LOAD_ERROR';

export const ARTICLES_LOAD = 'ARTICLES_LOAD';
export const ARTICLES_LOAD_SUCCESS = 'ARTICLES_LOAD_SUCCESS';
export const ARTICLES_LOAD_ERROR = 'ARTICLES_LOAD_ERROR';
export const ARTICLES_PORT_CONFIGUATION_LOAD = 'ARTICLES_PORT_CONFIGUATION_LOAD';
export const ARTICLES_PORT_CONFIGUATION_LOAD_SUCCESS = 'ARTICLES_PORT_CONFIGUATION_LOAD_SUCCESS';
export const ARTICLES_PORT_CONFIGUATION_LOAD_ERROR = 'ARTICLES_PORT_CONFIGUATION_LOAD_ERROR';
export const ARTICLES_FILTERS_CHANGE = 'ARTICLES_FILTERS_CHANGE';
export const ARTICLES_FILTERS_CONNECTION_FILTERS_CHANGE = 'ARTICLES_FILTERS_CONNECTION_FILTERS_CHANGE';

export const XPCS_LOAD = 'XPCS_LOAD';
export const XPCS_LOAD_SUCCESS = 'XPCS_LOAD_SUCCESS';
export const XPCS_LOAD_ERROR = 'XPCS_LOAD_ERROR';

export class Application {
    static ERROR_UNAUTHORIZED = 'REDUX.App.ErrorUnauthorized';
    // static CLEAR_LOGOUT_FLAG = 'REDUX.App.ClearLogoutFlag';
    static ERROR_WEBAPI = 'REDUX.App.ErrorWebApi';
    static ERROR_USERMADE = 'REDUX.App.UserError';
}

export class User {
    static SAGA_CHANGE_PWD = "SAGA.User.ChangePassword";
}

export class Calculation {
    static UPDATE_PARAMETERS = 'Calculation.UPDATE_PARAMETERS';
    static CALCULATE_BEGIN = 'Calculation.REDUX.BEGIN';
    static CALCULATE = 'Calculation.SAGA.PROCESS';
    static CALCULATE_SUCCESS = 'Calculation.REDUX.SUCCESS';
    static INPUTS_HEATEXCHANGERS_LOAD = 'Calculation._INPUTS_HEATEXCHANGERS_LOAD';
    static INPUTS_HEATEXCHANGERS_SUCCESS = 'REDUX.Calculation.INPUTS_HEATEXCHANGERS_SUCCESS';
    static INPUTS_HEATEXCHANGERS_ERROR = 'REDUX.Calculation.INPUTS_HEATEXCHANGERS_ERROR';

    static INPUTS_LOAD = 'Calculation.INPUTS_LOAD';
    static INPUTS_LOAD_SUCCESS = 'Calculation.INPUTS_LOAD_SUCCESS';
    static INPUTS_LOAD_ERROR = 'Calculation.INPUTS_LOAD_ERROR';

    static INPUTS_SAVE = 'CALCULATION_INPUTS_SAVE';
    static INPUTS_SAVE_SUCCESS = 'CALCULATION_INPUTS_SAVE_SUCCESS';
    static INPUTS_SAVE_ERROR = 'CALCULATION_INPUTS_SAVE_ERROR';

    static INPUTS_DEFAULT_SAVE = 'INPUTS_DEFAULT_SAVE';
    static INPUTS_DEFAULT_SAVE_SUCCESS = 'INPUTS_DEFAULT_SAVE_SUCCESS';
    static INPUTS_DEFAULT_SAVE_ERROR = 'INPUTS_DEFAULT_SAVE_ERROR';

    static INPUTS_DEFAULT_DELETE = 'INPUTS_DEFAULT_DELETE';
    static INPUTS_DEFAULT_DELETE_SUCCESS = 'INPUTS_DEFAULT_DELETE_SUCCESS';
    static INPUTS_DEFAULT_DELETE_ERROR = 'INPUTS_DEFAULT_DELETE_ERROR';

    static INPUTS_ALL_DEFAULT_DELETE = 'INPUTS_ALL_DEFAULT_DELETE';
    static INPUTS_ALL_DEFAULT_DELETE_SUCCESS = 'INPUTS_ALL_DEFAULT_DELETE_SUCCESS';
    static INPUTS_ALL_DEFAULT_DELETE_ERROR = 'INPUTS_ALL_DEFAULT_DELETE_ERROR';

    static FILTERS_SAVE = 'FILTERS_SAVE';
    static FILTERS_SAVE_SUCCESS = 'FILTERS_SAVE_SUCCESS';
    static FILTERS_SAVE_ERROR = 'FILTERS_SAVE_ERROR';
    static FILTERS_RESET = 'FILTERS_RESET';
    static FILTERS_RESET_SUCCESS = 'FILTERS_RESET_SUCCESS';
    static FILTERS_RESET_ERROR = 'FILTERS_RESET_ERROR';
    static FILTERS_CHANGE = 'FILTERS_CHANGE';

    static SET_INPUTS_VALIDITY = 'Calculation.REDUX.SetInputFormValidity';
    static OUTPUT_COLUMNS_CHANGE = 'OUTPUT_COLUMNS_CHANGE';
}

export class ProductBasket{
    static LOAD_ITEMS = "REDUX.ProductBasket.LOAD_ITEMS";
    static GET_SINGLE_ITEM = "REDUX.ProductBasket.GET_SINGLE_ITEM";
    static ITEM_UPDATE_QUANTITY = "REDUX.ProductBasket.ITEM_UPDATE_QUANTITY";
    static ITEM_UPDATE = "REDUX.ProductBasket.UPDATE_ITEM";
    static ITEM_DELETE = "REDUX.ProductBasket.DELETE_ITEM";
    static SAGA_LOAD_ITEMS = "SAGA.ProductBasket.GET_ITEMS";
    static SAGA_GET_ITEM = "SAGA.ProductBasket.GET_ITEM";
    static SAGA_ADD_ITEM = "SAGA.ProductBasket.ADD_ITEM";
    static SAGA_ITEM_UPDATE_QUANTITY = "SAGA.ProductBasket.UPDATE_QUANTITY";
    static SAGA_ITEM_UPDATE = "SAGA.ProductBasket.UPDATE_ITEM";
    static SAGA_ITEM_DELETE = "SAGA.ProductBasket.DELETE_ITEM";
    //Export to CRM
    static SAGA_ITEM_EXPORT_CRM = "SAGA_ITEM_EXPORT_CRM";
    static SAGA_ITEM_EXPORT_CRM_SUCCESS = "SAGA_ITEM_EXPORT_CRM_SUCCESS";
    static SAGA_ITEM_EXPORT_CRM_ERROR = "SAGA_ITEM_EXPORT_CRM_ERROR";
    //Export to Intershop
    static SAGA_ITEM_EXPORT_INTERSHOP = "SAGA_ITEM_EXPORT_INTERSHOP";
    static SAGA_ITEM_EXPORT_INTERSHOP_SUCCESS = "SAGA_ITEM_EXPORT_INTERSHOP_SUCCESS";
    static SAGA_ITEM_EXPORT_INTERSHOP_SUCCESS_ERROR = "SAGA_ITEM_EXPORT_INTERSHOP_SUCCESS_ERROR";
    static SAGA_ITEM_EXPORT_INTERSHOP_REDIRECT = "SAGA_ITEM_EXPORT_INTERSHOP_REDIRECT";
}

export class Auth{
    static SAGA_LOGIN = "SAGA.Auth.LOGIN";
    static SAGA_LOGIN_CX_PORTAL = "SAGA.Auth.LOGIN_CX_PORTAL";
    static REDUX_LOGED_IN = "REDUX.Auth.LOGED_IN";
    static REDUX_LOG_OUT = "REDUX.Auth.REDUX_LOG_OUT";
    static SAGA_HANDLE_AUTH = "SAGA.Auth.HANDLE_AUTH";
    static SAGA_RESET_PWD = "SAGA.Auth.RESET_PWD";
    static SAGA_REGISTER = "SAGA.Auth.REGISTER";
    static SAGA_VERIFY = "SAGA.Auth.VERIFY";
}

export class Connections {
    static TOGGLE_MODAL = "Connections.REDUX.ModalToggle";
    static SAGA_CALCULATE_CONNECTIONS = "SAGA.Connections.CALCULATE";
    static SAGA_CALCULATE_CONNECTION_FOR_ARTICLE = "SAGA.Connections.CALCULATE_FOR_ARTICLE";
    static SAGA_GET_PORT_INFO = "SAGA.Connections.GetPortInfo";
    static SAGA_GET_CONNECTION_IMPACT = "SAGA.Connections.GetConnectionImpactData";
    static REDUX_CONNECTIONS_CALCULATE = 'REDUX.Connections.CONNECTIONS_CALCULATE';
    static CONNECTIONS_FILTERS_LOAD = 'REDUX.Connections.LoadFilters';
    static FILTER_LOADING_TOGGLE = 'REDUX.Connections.LoadingFilterIndicatorToggle';
    static CALCULATION_LOADING_TOGGLE = 'REDUX.Connections.LoadingCalculationIndicatorToggle';
}

export class Constants{
    static SAGA_LOADCOUNTRIES = "SAGA.Constants.LOADCOUNTRIES";
    static REDUX_LOADCOUNTRIES = "REDUX.Constants.LOADCOUNTRIES";
}

//Wiki
export const WIKI_GET_PAGE = "SAGA.WIKI_GET_PAGE";
export const WIKI_SAVE_MESSAGES = "Redux.WIKI_SAVE_MESSAGES";

//XReference tool
export const XREF_LOAD_PRODUCTS = "SAGA.XREF.LoadProducts";
export const XREF_SAGA_CALCULATE = "SAGA.XREF.Calculate";
export const XREF_PRODUCTS_LOADED = "REDUX.XREF_PRODUCTS_LOADED";

//Multicalc
export const MC_REDUX_LOAD_CALCINPUTS = "MULTICALC.REDUX_LOAD_CALCINPUTS";
export const MC_SAGA_LOAD_CALCINPUTS = "MULTICALC.SAGA_LOAD_CALCINPUTS";
export const MC_SAGA_CALCULATE = "MULTICALC.SAGA_CALCULATE";
export const MC_SAGA_SAVE_INPUTS = "MULTICALC.SAGA_SAVE_INPUTS";
export const MC_SAGA_LOAD_SAVED_CALCS = "MULTICALC.SAGA_LOAD_SAVED_CALCS";
export const MC_REDUX_LOAD_SAVED_CALCS = "MULTICALC.REDUX_LOAD_SAVED_CALCS";
export const MC_SAGA_LOAD_SAVED_CALCULATION = "MULTICALC.SAGA_LOAD_SAVED_CALCULATION";
export const MC_SAGA_CALCULATION_LOAD_XHS = "MULTICALC.SAGA_CALCULATION_LOAD_XHS";
export const MC_REDUX_CALCULATION_LOAD_XHS = "MULTICALC.REDUX_CALCULATION_LOAD_XHS";
export const MC_SET_CALC_TYPE = "MULTICALC.REDUX_SET_CALCULATION_TYPE";
export const MC_ADD_CALCULATION = "MULTICALC.REDUX_ADD_CALCULATION";
export const MC_ADD_SAVED_CALCULATIONS = "MULTICALC.REDUX_ADD_SAVED_CALCULATIONS";
export const MC_CALCULATION_SET_RESULT = "MULTICALC.REDUX_CALCULATION_SET_RESULT";
export const MC_CALCULATION_START = "MULTICALC.REDUX_CALCULATION_START";
export const MC_CALCULATION_END = "MULTICALC.REDUX_CALCULATION_END";
export const MC_UPDATE_INPUTS = "MULTICALC.REDUX_UPDATE_INPUTS";
export const MC_DELETE_INPUTS = "MULTICALC.REDUX_DELETE_INPUTS";
export const MC_CLEAR = "MULTICALC.CLEAR";
export const MC_UPDATE_FILTERS = "MULTICALC.REDUX_UPDATE_FILTERS";
export const MC_SET_INPUTS_VALIDITY = "MULTICALC.REDUX_SET_INPUTS_VALIDITY";
export const MC_SAGA_UPDATE_CALC_LIST = "MULTICALC.SAGA_UPDATE_CALC_LIST";
export const MC_REDUX_UPDATE_CALC_LIST = "MULTICALC.REDUX_UPDATE_CALC_LIST";
export const MC_SAGA_DELETE_CALC = "MULTICALC.SAGA_DELETE_CALC";
export const MC_REDUX_DELETE_CALC = "MULTICALC.REDUX_DELETE_CALC";
export const MC_SAGA_EXCEL_IMPORT = "MULTICALC.SAGA_EXCEL_IMPORT";
export const MC_REDUX_SET_EXPORT_CONFIG = "MULTICALC.REDUX_SET_EXPORT_CONFIG";
export const MC_SAGA_GET_EXPORT_CONFIG = "MULTICALC.SAGA_GET_EXPORT_CONFIG";
export const MC_SAGA_SAVE_EXPORT_CONFIG = "MULTICALC.SAGA_SAVE_EXPORT_CONFIG";
export const MC_SAGA_RESET_EXPORT_CONFIG = "MULTICALC.SAGA_RESET_EXPORT_CONFIG";
export const MC_REDUX_OPENFROMCALCWINDOW = "MULTICALC.REDUX_OPENFROMCALCWINDOW";

// EasySelect
export const ES_SAGA_LOAD_INPUTS = "EasySelect.SAGA_LOAD_INPUTS";
export const ES_REDUX_LOAD_INPUTS = "EasySelect.REDUX_LOAD_INPUTS";
export const ES_SAGA_CALCULATE = "EasySelect.SAGA_CALCULATE";
export const ES_SAGA_LOAD_USER_PROFILE = "EasySelect.SAGA_LOAD_USER_PROFILE";
export const ES_REDUX_LOAD_USER_PROFILE = "EasySelect.REDUX_LOAD_USER_PROFILE";

